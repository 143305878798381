.body {
  display: grid;
  flex: 1;
  grid-auto-rows: min-content;
}

.cell {
  align-items: center;
  contain: strict;
  content-visibility: auto;
  display: grid;
  height: 46px;
  justify-content: stretch;
  overflow: hidden;
  padding: 6px 16px; /* override MUI "last child" rule */
  text-align: inherit;

  &.dynamicHeight {
    contain: initial;
    content-visibility: initial;
    height: initial;
  }

  .detailsOpen > & {
    border-bottom-color: transparent;
  }
}

.detailsRow {
  display: none;

  .detailsOpen > & {
    display: contents;
  }
}

.detailsCell {
  grid-column: 2 / -1;
  padding: 0;

  .root {
    overflow: auto;
    background-image: var(--mui-overlays-1);

    .head .cell {
      height: 46px;
    }

    .row:last-child {
      .cell {
        border-bottom-color: transparent;
      }
    }
  }
}

.head {
  display: grid;
  position: sticky;
  text-align: center;
  top: 0;
  z-index: 1;
}

.head .cell {
  height: 65px;
}

.root {
  background-color: var(--mui-palette-background-paper);
  background-image: var(--mui-overlays-0);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  overflow-x: auto;
  width: 100%;
  will-change: transform;
}

.row {
  display: contents;
}

.toggleDetails {
  padding: 6px;
}

.toggleDetails button {
  transform: rotate(-90deg);
  transition: 120ms transform ease-in-out;

  .detailsOpen & {
    transform: rotate(0deg);
  }
}
